import { Prisma } from "@fscrypto/database";
import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";
import { PartnerOfferingChain } from "./partner-offering-chain";

export type PartnerOfferingType = "DATA" | "INSIGHTS" | "EARN" | "GRAIL" | "TOOLS" | "QBR";
export const partnerOfferingTypesAsArray = ["DATA", "INSIGHTS", "EARN", "GRAIL", "TOOLS", "QBR"] as const;

export type PartnerOffering = Identity<
  Omit<Prisma.PartnerOfferingGetPayload<{}>, "type"> & {
    type: PartnerOfferingType;
  }
>;

export type PartnerOfferingWithChains = Identity<
  PartnerOffering & {
    partnerOfferingChains: PartnerOfferingChain[];
  }
>;

export type PartnerOfferingNew = Pick<PartnerOffering, "partnerId" | "type">;

export const partnerOfferingNewSchema = z.object({
  partnerId: z.string().uuid(),
  type: z.enum(partnerOfferingTypesAsArray),
}) satisfies ZodSchema<PartnerOfferingNew>;
