import { Prisma } from "@fscrypto/database";
import { z } from "zod";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";
import { PartnerOfferingWithChains } from "./partner-offering";

export type Partner = Prisma.PartnerGetPayload<{}>;
export type PartnerWithAssociations = Identity<
  Partner & {
    offerings: PartnerOfferingWithChains[];
  }
>;

export type PartnerNew = Pick<Partner, "displayName" | "slug" | "tier"> & {
  ecoLeadId?: string | null;
};

export const partnerSchema = z.object({
  id: z.string().uuid(),
  displayName: z.string(),
  slug: z.string(),
  tier: z.number(),
  ecoLeadId: z.string().uuid().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
}) satisfies ZodSchema<PartnerNew>;

export const partnerCreateSchema = z.object({
  displayName: z.string(),
  slug: z.string(),
  tier: z.number(),
  ecoLeadId: z.string().uuid().nullable().optional(),
}) satisfies ZodSchema<PartnerNew>;

export type PartnerUpdate = Pick<Partner, "id" | "displayName" | "slug" | "tier"> & {
  ecoLeadId?: string | null;
};

export const partnerUpdateSchema = z.object({
  id: z.string().uuid(),
  displayName: z.string(),
  slug: z.string().min(1),
  tier: z.number(),
  ecoLeadId: z.string().uuid().nullable().optional(),
}) satisfies ZodSchema<PartnerUpdate>;
