import { Prisma } from "@fscrypto/database";
import { z } from "zod";
import { Chain } from "../chain";
import { Identity } from "../utils/identity";
import { ZodSchema } from "../utils/zod";

export type PartnerOfferingChainType = "SCORES" | "VALIDATION" | "PAYMENTS" | "DEFAULT";
export const partnerOfferingChainTypesAsArray = ["SCORES", "VALIDATION", "PAYMENTS", "DEFAULT"] as const;

export type PartnerOfferingChain = Identity<
  Omit<Prisma.PartnerOfferingChainGetPayload<{}>, "type"> & {
    type: PartnerOfferingChainType;
    chain: Chain;
  }
>;

export type PartnerOfferingChainNew = Pick<PartnerOfferingChain, "partnerOfferingId" | "chainId" | "type">;

export const partnerOfferingChainNewSchema = z.object({
  partnerOfferingId: z.string().uuid(),
  chainId: z.string().uuid(),
  type: z.enum(partnerOfferingChainTypesAsArray),
}) satisfies ZodSchema<PartnerOfferingChainNew>;
